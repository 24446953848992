//
// Sidebar
//

// Desktop mode
@include media-breakpoint-up(lg) {
  .app-sidebar-toggle {
    box-shadow: var(--#{$prefix}app-sidebar-base-toggle-btn-box-shadow) !important;
    background-color: var(--#{$prefix}app-sidebar-base-toggle-btn-bg-color) !important;
    border: 1px solid var(--#{$prefix}app-sidebar-base-border-color) !important;

    .active i {
      transform: rotateZ(0deg) !important;
    }
  }

  [dir='rtl'] {
    .app-sidebar-toggle {
      i {
        transform: rotateZ(180deg);
      }

      &.active i {
        transform: rotateZ(0deg) !important;
      }
    }
  }

  .app-sidebar-logo,
  .app-sidebar-profile {
    height: calc(var(--#{$prefix}app-header-height) + 1px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-shrink: 0;
  }

  .app-sidebar-menu {
    .menu {
      // Root
      > .menu-item {
        margin-left: 0.115rem;

        > .menu-link {
          > .menu-title {
            font-weight: $font-weight-bold;
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .app-sidebar-logo {
    display: none;
  }
}
