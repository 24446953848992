.react-tel-input .special-label {
  display: none !important;
}
.react-tel-input .form-control-solid {
  padding: 0.775rem 1rem 0.775rem 60px !important;
  background-color: $input-solid-bg !important;
  border-color: $input-solid-bg !important;
  color: $input-solid-color !important;
  @include placeholder($input-solid-placeholder-color);
  transition: $transition-input !important;

  .dropdown.show > &,
  &:active,
  &.active,
  &:focus,
  &.focus {
    background-color: $input-solid-bg-focus !important;
    border-color: $input-solid-bg-focus !important;
    color: $input-solid-color !important;
    transition: $transition-input !important;
  }
}

form {
  max-width: 450px;
  margin: 0 auto;
}
@media (orientation: landscape) {
  form {
    max-width: 400px;
    margin: 0 auto;
  }
}
.pagination {
  .page-item {
    cursor: pointer;
  }
}

.upgrade-plan {
  display: block;
  padding: 0.15rem 0;
  color: var(--bs-text-light);
  background-color: #202647;
  margin: 2rem 0;
  border-radius: 1rem;
  &--inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 2rem;
    transition: none;
    outline: none;
    color: #dbdfe9;

    h3 {
      color: #ffffff;
      margin-bottom: 1.2rem;
    }
    p {
      color: var(--bs-text-muted);
    }
    .footer {
    }
  }
}

@media (min-width: 992px) {
  .upgrade-plan {
    margin-left: 0.115rem;
    &--inner {
    }
  }
}
.app-sidebar .menu > .menu-item .menu-link.active {
  background-color: #3e97ff !important;
}
.ant-picker-input > input,
.ant-picker-input > input:placeholder-shown,
.ant-picker-suffix {
  background-color: $input-solid-bg !important;
  border-color: $input-solid-bg !important;
  color: $input-solid-color !important;
  @include placeholder($input-solid-placeholder-color);
  transition: $transition-input !important;

  &:active,
  &.active,
  &:focus,
  &.focus {
    // background-color: $input-solid-bg-focus !important;
    border-color: $input-solid-bg-focus !important;
    color: $input-solid-color !important;
    transition: $transition-input !important;
  }
}

// react-select
.select2 {
  &-container {
    margin: 0;
    box-sizing: border-box;
    position: relative;
    vertical-align: middle;
  }

  &__control {
  }
  &__multi-value {
    &__label {
    }
    &__remove {
    }
  }
  &__value {
    &-container {
    }
    &-inner {
    }
  }
  &__indicators {
  }
  &__menu {
    &-list {
    }
  }
  &__option {
    &--is-focused {
    }
  }
}

//
// Select2 plugin customization: https://select2.org
//

$select2-dropdown-padding: 1rem 0;
$select2-dropdown-option-padding: 0.75rem 1.25rem;
$select2-dropdown-option-margin: 0 0;
$select2-dropdown-option-check-padding: 1.25rem;

$select2-dropdown-group-padding: 0 1.25rem 0 1.25rem;
$select2-dropdown-group-margin: 0 0 0.25rem 0;

$select2-search-padding: 0.5rem 1.25rem;
$select2-search-margin: 0 0 0.5rem 0;

$select2-clear-size: 0.7rem;
$select2-clear-color: var(--#{$prefix}gray-700);
$select2-clear-color-dark: var(--#{$prefix}gray-700);
$select2-clear-color-hover: var(--#{$prefix}primary);
$select2-clear-right: $form-select-indicator-padding; //$form-select-padding-x +

// Bootstrap .form-control sizing integration
$select2-tag-clear-size: 0.6rem;

$select2-tag-padding-y-sm: 0.1rem;
$select2-tag-padding-x-sm: 0.35rem;
$select2-input-padding-y-sm: $input-padding-y-sm - $select2-tag-padding-y-sm * 2;

$select2-tag-padding-y: 0.1rem;
$select2-tag-padding-x: 0.5rem;
$select2-input-padding-y: $input-padding-y - $select2-tag-padding-y * 2;

$select2-tag-padding-y-lg: 0.15rem;
$select2-tag-padding-x-lg: 0.65rem;
$select2-input-padding-y-lg: $input-padding-y-lg - $select2-tag-padding-y-lg * 2;

// Dropdown
.select2-dropdown {
  border: 0;
  box-shadow: var(--#{$prefix}dropdown-box-shadow);
  border-radius: $dropdown-border-radius;
  padding: $select2-dropdown-padding;
  background-color: var(--#{$prefix}dropdown-bg);

  // In modal
  .modal-open & {
    z-index: $zindex-modal + 1;
  }

  // Search
  .select2-search {
    padding: $select2-search-padding;
    margin: $select2-search-margin;

    .select2-search__field {
      background-color: var(--#{$prefix}body-bg);
      padding: $input-padding-y-sm $input-padding-x-sm;
      color: $input-color;
      font-size: $input-font-size-sm;
      border: 1px solid $input-border-color;
      border-radius: $input-border-radius-sm;
      outline: 0 !important;

      &:focus,
      &:active {
        border: 1px solid $input-focus-border-color;
      }
    }
  }

  // Options
  .select2-results > .select2-results__options {
    max-height: 250px;
    overflow-y: auto;
  }

  // Option
  .select2-results__option {
    color: var(--#{$prefix}gray-700);
    transition: $transition-link;
    padding: $select2-dropdown-option-padding;
    margin: $select2-dropdown-option-margin;

    &.select2-results__option--highlighted {
      background-color: var(--#{$prefix}component-hover-bg);
      color: var(--#{$prefix}component-hover-color);
      transition: $transition-link;
    }

    &.select2-results__option--selected {
      background-color: var(--#{$prefix}component-hover-bg);
      color: var(--#{$prefix}component-hover-color);
      transition: $transition-link;
      position: relative;

      &:after {
        top: 50%;
        display: block;
        position: absolute;
        transform: translateY(-50%);
        height: 0.75rem;
        width: 0.75rem;
        content: '';
        @include svg-bg-icon(check, var(--#{$prefix}component-hover-color));
        mask-position: center;
        -webkit-mask-position: center;
        right: $select2-dropdown-option-check-padding;
      }
    }

    &.select2-results__option--disabled {
      color: var(--#{$prefix}gray-400);
    }

    &.select2-results__message {
      color: var(--#{$prefix}gray-600);
    }

    &.select2-results__option--group {
      padding-left: 0;
      padding-right: 0;

      .select2-results__group {
        display: block;
        color: var(--#{$prefix}gray-800);
        font-weight: $font-weight-semibold;
        font-size: $h5-font-size;
        padding: $select2-dropdown-group-padding;
        margin: $select2-dropdown-group-margin;
      }

      .select2-results__option {
        padding: $select2-dropdown-option-padding;
        margin: $select2-dropdown-option-margin;
      }
    }
  }
}
